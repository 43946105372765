import { Link, useLoaderData } from "react-router-dom";
import CustomServices from "./../services/services";
import { GeventFetchUrl, GeventModel, WinnerModel } from "./../model/Gevent.model";
import React, { useContext, useRef, useState } from "react";
import { LoginContext, PopUpContext } from "./../store/context";
import CodeGenerator from "./../component/staff/CodeGenerator";
import style from "./stylepage/Gevent.module.css"

function Gevent() {
  let [user , setUser] = useContext(LoginContext);  
  let gevent = useLoaderData() as GeventModel;
  const [popUp, setPopUp] = useContext(PopUpContext);

  //const data  = useState<{[name:string]: string}>({email:"" , code: ""});
  // const error = useState<{[name:string]: string}>({});
  // const searchRef = useRef<HTMLInputElement>();
  // const nameRef = useRef<HTMLInputElement>();
  // const surnameRef = useRef<HTMLInputElement>();
  // const winnerList = useState<WinnerModel[]>([]);

  const isStaff = user?.id &&  gevent.staff.includes(user.id);
  const isMaster = user?.id &&  gevent.master.includes(user.id);
  
//  async function searchWinner(e: React.FormEvent<HTMLFormElement>){
//   e.preventDefault();
//   //data[0].email = data[0].email.toString().toLocaleLowerCase();
//   let searchString = searchRef.current.value.length === 0 ? "" : "search="+searchRef.current.value + "&";
//   searchString = nameRef.current.value.length === 0 ? searchString : searchString+"name="+nameRef.current.value+ "&";
//   searchString = surnameRef.current.value.length === 0 ? searchString : searchString+"surname="+surnameRef.current.value;
  
//   let response = await CustomServices.sendFetch<WinnerModel[]>(GeventFetchUrl.getFindWinner(gevent._id.toString(),searchString), {
//     method:"GET"
//   })

//   if(response.error){
//     setPopUp({message:<p>{response.error.general}</p>})
//   }else{
//     //data[1](prev => {return response.data})
//     winnerList[1](prev => {return response.data});
//   }
//  }

//  async function toggleWinnerWithdrawn(e: React.MouseEvent<HTMLButtonElement, MouseEvent>){
//   e.preventDefault();
//   //let toggle = !winner[0].withdrawn;

//   // let response = await CustomServices.sendFetch<WinnerModel>(GeventFetchUrl.setWinner(gevent._id.toString()), {
//   //   method:"POST",
//   //   body: JSON.stringify({
//   //     code: winner[0].code,
//   //     email:winner[0].email,
//   //     withdrawn: toggle
//   //   })
//   // });

//   // if(response.error){
//   //   console.log("qui")
//   //   setPopUp({message:<p>{response.error.general}</p>})
//   // }else{
//   //   winner[1](prev => {return {...prev, withdrawn: toggle }});
//   // }
//  }

  return <div className="max900 m_center">
    <h2 className="t_center">{gevent.name}</h2>
    <p className="t_center">{gevent.description}</p>
    {/*TODO: inserire html custom per evento 24/06/2024 */}
    {/*<div dangerouslySetInnerHTML={{__html:event.desHtml}} /> */}
    {/* {((isStaff || isMaster)) && <>
        <h3 className="t_center">Verifica il vincitore</h3>
        <form onSubmit={searchWinner}>
          <div>
            <label>ricerca</label>
            <input ref={searchRef} placeholder="cerca" type="text"/>
          </div>
          <div>
            <label>nome</label>
            <input ref={nameRef} placeholder="nome" type="text"/>
          </div>
          <div>
            <label>cognome</label>
            <input ref={surnameRef} placeholder="cognome" type="text"/>
          </div>
          <button type="submit">cerca</button>
        </form>
        {winnerList[0].length > 0 && <>
        <div className={style.box}>
          <table className={style.table}>
              <thead>
                <th>Codice</th>
                <th>Email</th>
                <th>Nome</th>
                <th>Cognome</th>
                <th>aggiorna</th>
              </thead>
              <tbody>
                {winnerList[0].map(winner => {
                  
                  return <tr>
                    <td>{winner.code}</td>
                    <td>{winner.email}</td>
                    <td>{winner.name}</td>
                    <td>{winner.surname}</td>
                    <td>
                      <button type="button" onClick={toggleWinnerWithdrawn}>
                        {winner.name ? "Ritirato" : "Non ritirato"}
                      </button>
                    </td>
                  </tr>
                })}
                
              </tbody>
              <tfoot>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tfoot>
            </table>
        </div>
          
        </>}
      </>
    } */}
    {((isStaff || isMaster) && !gevent.FA) && <>
        <CodeGenerator idGevent={gevent._id} maxTry={5}/>
      </>
    }
    <div className="btn-box btn-box_center">
      <Link className="btn" to="play" title="gioca">Gioca</Link>

    </div>
  </div>
  }

export async function loaderGeventPage(name: string){

  let geventResp = await CustomServices.sendFetch<GeventModel>(GeventFetchUrl.getGevent(name.replaceAll("-"," ")), {
      method: "GET",
  })

  if(geventResp.error){
    alert(geventResp.error.general)
    window.location.href = "../"
  }

  localStorage.setItem(`${name}_src_play`, geventResp.data.src)
  return geventResp.data;
}

export default Gevent;